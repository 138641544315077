import 'babel-polyfill'
import './my.styl';
import p1j from './img/p1j.jpg';
import p1e from './img/p1e.jpg';
import p2j from './img/p2j.jpg';
import p2e from './img/p2e.jpg';
import p3j from './img/p3j.jpg';
import p3e from './img/p3e.jpg';
import p4j from './img/p4j.jpg';
import p4e from './img/p4e.jpg';
import p5j from './img/p5j.jpg';
import p5e from './img/p5e.jpg';

let lang = "ja";

const html = document.querySelector("html");
const fade = document.querySelector(".fade");
const langButton = document.querySelector(".lang");

const imageSrc = [
  {
    "imgJp": p1j,
    "capJp": "「動き出す自邸」©黒田菜月 / 撮影:2020年5月31日",
    "capmJp": "「動き出す自邸」©黒田菜月 / 2020年5月31日",
    "imgEn": p1e,
    "capEn": "“our house is Moving” ©Natsuki Kuroda / Shoot at 12th, Sep, 2021",
    "capmEn": "“our house is Moving” ©Natsuki Kuroda / 12th, Sep, 2021"
  },
  {
    "imgJp": p2j,
    "capJp": "「辻堂の引っ越し」©長谷川健太 / 撮影:2023年2月8日",
    "capmJp": "「辻堂の引っ越し」©長谷川健太 / 2023年2月8日",
    "imgEn": p2e,
    "capEn": "“Moving in Tsujido” / Shoot at 30th, Mar, 2022",
    "capmEn": "“Moving in Tsujido” / 30th, Mar, 2022"
  },
  {
    "imgJp": p3j,
    "capJp": "「引っ越しの建築」©伊丹豪 / 撮影:2023年5月31日",
    "capmJp": "「引っ越しの建築」©伊丹豪 / 2023年5月31日",
    "imgEn": p3e,
    "capEn": "“Stille Post” ©goitami / Shoot at 9th, Mar, 2023",
    "capmEn": "“Stille Post” ©goitami / 9th, Mar, 2023"
  },
  {
    "imgJp": p4j,
    "capJp": "「貴布祢の階段」©yasukojima / 撮影:2024年3月29日",
    "capmJp": "「貴布祢の階段」©yasukojima / 2024年3月29日",
    "imgEn": p4e,
    "capEn": "“The Stairs of Kibune” / Shoot at 3rd, Aug, 2023",
    "capmEn": "“The Stairs of Kibune” / 3rd, Aug, 2023"
  },
  {
    "imgJp": p5j,
    "capJp": "「続・引っ越しの建築」©伊丹豪 / 撮影:2023年10月15日",
    "capmJp": "「続・引っ越しの建築」©伊丹豪 / 2023年10月15日",
    "imgEn": p5e,
    "capEn": "“architecture as Moving” ©goitami / Shoot at 31th, May, 2023",
    "capmEn": "“architecture as Moving” ©goitami / 31th, Mar, 2023"
  }
];
const imageConst = Math.floor(Math.random()*imageSrc.length);
const imageRand = imageSrc[imageConst];

console.log(imageRand);
document.querySelector(".img-jp").src = imageRand.imgJp;
document.querySelector(".img-en").src = imageRand.imgEn;
document.querySelector(".imgm-jp").src = imageRand.imgJp;
document.querySelector(".imgm-en").src = imageRand.imgEn;
document.querySelector(".cap-jp").innerHTML = imageRand.capJp;
document.querySelector(".cap-en").innerHTML = imageRand.capEn;
document.querySelector(".capm-jp").innerHTML = imageRand.capmJp;
document.querySelector(".capm-en").innerHTML = imageRand.capmEn;


async function toggleLang() {
  fade.classList.add("out");
  if(lang == "ja") {
    lang = "en"
    langButton.textContent = "JP"
  } else {
    lang = "ja"
    langButton.textContent = "EN"
  }
  await new Promise((resolve) => setTimeout(resolve, 500));
  html.lang = lang;
  await new Promise((resolve) => setTimeout(resolve, 200));
  fade.classList.remove("out");
}

langButton.addEventListener("click", toggleLang);
